import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Dialog, Grid } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import TheatersIcon from '@material-ui/icons/Theaters';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { useData } from '../DataProvider';
import VideoDialog from './VideoDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function WatchListAccordion({ card }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [video, setVideo] = useState('');

  const { jsonData } = useData();

  const { collections } = jsonData;
  const { collection } = card;

  const additionalFiles = collections.filter((coll) => coll.id === +collection)[0].files || [];

  const handleClose = () => {
    setShowVideoPlayer(false);
  };
  const loadVideo = (file) => {
    setVideo(file);
    setShowVideoPlayer(true);
  };

  const getIconByFileEnding = (file) => {
    const { filename, url } = file.file;

    if (filename && filename.toLowerCase().endsWith('.pdf')) {
      return (
        <>
          <PictureAsPdfIcon />
          <a
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 10, color: '#414141', textDecoration: 'none', verticalAlign: 'super' }}
          >
            {file.filename}
          </a>
        </>
      );
    }
    if (
      filename &&
      (filename.toLowerCase().endsWith('.png') ||
        filename.toLowerCase().endsWith('.jpg') ||
        filename.toLowerCase().endsWith('.jpeg'))
    ) {
      return (
        <>
          <ImageIcon />
          <a
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 10, color: '#414141', textDecoration: 'none', verticalAlign: 'super' }}
          >
            {filename}
          </a>
        </>
      );
    }
    if (
      filename &&
      (filename.toLowerCase().endsWith('.mp4') ||
        filename.toLowerCase().endsWith('.mpeg') ||
        filename.toLowerCase().endsWith('.webm'))
    ) {
      return (
        <>
          <TheatersIcon />
          <Button
            type="button"
            onClick={() => loadVideo(file)}
            style={{
              fontsize: '14px !important',
              marginLeft: 5,
              color: '#414141',
              textDecoration: 'none',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              verticalAlign: 'super',
              textTransform: 'capitalize',
            }}
            labelStyle={{ fontSize: '14px' }}
          >
            {filename}
          </Button>
        </>
      );
    }
    return <div style={{ marginLeft: 10 }}>{filename}</div>;
  };
  return (
    <div className={classes.root}>
      <Accordion style={{ background: '#F8F8F8' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography style={{ textTransform: 'capitalize' }} className={classes.heading}>
            {t('additional_info')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justify="flex-start">
            {additionalFiles.map((additionalFile) => (
              <Grid key={additionalFile.file.filename} style={{ textAlign: 'left' }} item xs={6}>
                {getIconByFileEnding(additionalFile)}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        width="xl"
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showVideoPlayer}
      >
        <VideoDialog src={video} close={() => handleClose()} />
      </Dialog>
    </div>
  );
}
WatchListAccordion.propTypes = {
  card: PropTypes.instanceOf(Object).isRequired,
};
