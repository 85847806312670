import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import languages from '../data/i18n.json';

const detectionOptions = {
  order: ['querystring', 'localStorage'],
  lookupFromPathIndex: 0,
  lookupQuerystring: 'lang',
  lookupLocalStorage: 'i18nextLng',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'de',
      detection: detectionOptions,
      keySeparator: false, // we do not use keys in form messages.welcome
      caches: ['localStorage', 'cookie'],

      resources: languages,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      debug: false,
      react: {
        wait: true,
      },
    },
    (err, t) => {
      if (err) console.error(err);
    },
  );

export default i18n;
