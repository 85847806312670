import { Player } from 'video-react';
import React from 'react';
import * as PropTypes from 'prop-types';
import { DialogActions, IconButton } from '@material-ui/core';
import PlayToggle from 'video-react/lib/components/control-bar/PlayToggle';
import ControlBar from 'video-react/lib/components/control-bar/ControlBar';
import 'video-react/dist/video-react.css';
import CloseIcon from '@material-ui/icons/Close';

const VideoDialog = ({ src, close }) => {
  return (
    <div style={{ width: 820 }}>
      <DialogActions>
        <IconButton aria-label="close" onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <Player autoPlay poster="/assets/poster.png" src={src.file?.url}>
        <ControlBar autoHide={false}>
          <PlayToggle />
        </ControlBar>
      </Player>
    </div>
  );
};

VideoDialog.propTypes = {
  src: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default VideoDialog;
