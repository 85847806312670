import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useColor } from 'react-color-palette';
import { useTheme } from '@material-ui/core';

const DataContext = React.createContext();

export default function DataProvider({ children }) {
  const theme = useTheme();
  const storedMap = localStorage.watchMap ? new Map(JSON.parse(localStorage.watchMap)) : new Map();
  const storedFullscreenMode = localStorage.fullscreen ? localStorage.getItem('fullscreen') === 'true' : false;
  const [cardsMap, setCardsMap] = useState(new Map());
  const [watchMap, setWatchMap] = useState(storedMap);
  const [jsonData, setJsonData] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedSearchItems, setSelectedSearchItems] = useState({});
  const [selectedId, setSelectedId] = useState({});
  const [filterItems, setFilterItems] = useState({});
  const [selectedItemObject, setSelectedItemObject] = useState({});
  const [selectedScenePage, setSelectedScenePage] = useState(1);
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0);
  const [selectedSceneId, setSelectedSceneId] = useState();
  const [isFullScreen, setFullScreen] = useState(storedFullscreenMode);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [linkStatus, setLinkStatus] = useState(0);
  const [wallColor, setWallColor] = useColor('hex', theme.palette.wall.color);
  const [wallColorRight, setWallColorRight] = useColor('hex', theme.palette.wall.color);
  const [highlightColor, setHighlightColor] = useState(theme.palette.select.color);
  const [selectedItemsLeft, setSelectedItemsLeft] = useState({});
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [hasPrivacyPolicy, setPrivacyPolicy] = useState(true);
  const [hasBanner, setBanner] = useState(true);
  const [allScenes, setAllScenes] = useState([]);
  const [sceneTypeId, setSceneTypeId] = useState(null);
  const [currentScene, setCurrentScene] = useState(null);
  const [setId, setSetId] = useState(null);
  const [searchText, setSearchText] = useState('');

  DataProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  function addPaginationCard(key, cardInfos) {
    const newMap = new Map(watchMap);
    newMap.set(key, cardInfos);
    setWatchMap(newMap);
  }

  function deletePaginationCard(key) {
    const newMap = new Map(watchMap);
    newMap.delete(key);
    setWatchMap(newMap);
  }

  function deleteAllPaginationCards() {
    const newMap = new Map();
    setWatchMap(newMap);
  }

  function updatePaginationCard(id, cardInfos) {
    if (cardInfos.isFavorite) {
      addPaginationCard(id, cardInfos);
    } else {
      deletePaginationCard(id);
    }
  }

  function addPaginationCardById(id) {
    const cardObjects = [...cardsMap.values()];
    const index = cardObjects.findIndex((el) => el.product.id === id);
    const cardInfos = cardObjects[index];
    cardInfos.isFavorite = true;
    updatePaginationCard(id, cardInfos);
  }

  function removePaginationCardById(id) {
    const cardObjects = [...cardsMap.values()];
    const key = cardObjects.findIndex((el) => el.product.id === id);
    const cardInfos = cardObjects[key];
    cardInfos.isFavorite = false;
    deletePaginationCard(id);
  }

  function updateWatchMap(map) {
    setWatchMap(map);
  }

  useEffect(() => {
    localStorage.removeItem('watchMap');
    localStorage.watchMap = JSON.stringify(Array.from(watchMap.entries()));
  }, [watchMap]);

  return (
    <DataContext.Provider
      value={{
        watchMap,
        updateWatchMap,
        cardsMap,
        user,
        setUser,
        updateCard: updatePaginationCard,
        setCardsMap,
        jsonData,
        setJsonData,
        deletePaginationCard,
        deleteAllPaginationCards,
        selectedId,
        selectedItemObject,
        setSelectedItemObject,
        setSelectedId,
        selectedScenePage,
        setSelectedScenePage,
        isFullScreen,
        setFullScreen,
        selectedSceneIndex,
        setSelectedSceneIndex,
        selectedSceneId,
        setSelectedSceneId,
        selectedProduct,
        setSelectedProduct,
        addPaginationCardById,
        removePaginationCardById,
        linkStatus,
        setLinkStatus,
        wallColor,
        setWallColor,
        wallColorRight,
        setWallColorRight,
        selectColor: highlightColor,
        setSelectColor: setHighlightColor,
        selectedItems,
        setSelectedItems,
        filterItems,
        setFilterItems,
        selectedItemsLeft,
        setSelectedItemsLeft,
        selectedSearchItems,
        setSelectedSearchItems,
        userId,
        setUserId,
        userData,
        setUserData,
        isLoading,
        setLoading,
        hasPrivacyPolicy,
        setPrivacyPolicy,
        hasBanner,
        setBanner,
        allScenes,
        setAllScenes,
        sceneTypeId,
        setSceneTypeId,
        currentScene,
        setCurrentScene,
        setId,
        setSetId,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  return React.useContext(DataContext);
}
