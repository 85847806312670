import axios from 'axios';
// Create instance called instance
const instance = axios.create({
  baseURL: 'https://sattler-backend-dev.datengarage.com/',
});

export default {
  fetchData: (customerSet) =>
    instance({
      method: 'POST',
      url: `/api/sattler/getCustomerSet/?set_id=${customerSet}`,
    }),
  postData: () =>
    instance({
      method: 'POST',
      url: '/api',
    }),

  sendEmailRequest: (data) =>
    instance({
      method: 'POST',
      url: '/api/sattler/email',
      data,
    }),

  sendCustomerRequest: (data) =>
    instance({
      method: 'POST',
      url: '/api/sattler/sattler_customer_request',
      data,
    }),

  init: () =>
    instance({
      method: 'POST',
      url: '/?json=get_nonce&controller=user&method=register',
    }),
  login: (loginData) =>
    instance({
      method: 'POST',
      url: `api/auth/generate_auth_cookie/?username=${loginData.emailAdress}&password=${loginData.password}`,
    }),
  resetPassword: (email) =>
    instance({
      method: 'POST',
      url: `api/user/retrieve_password?user_login=${email}`,
    }),
  setNewPassword: (data) =>
    instance({
      method: 'POST',
      url: `api/sattler/resetUserPassword`,
      data,
    }),

  register: (registerData) =>
    instance({
      method: 'GET',
      url: `/?json=user/register&username=${registerData.email}
    &email=${registerData.email}&nonce=${registerData.nonce}&display_name=${registerData.email}
    &notify=both&user_pass=${registerData.password}&insecure=cool`,
    }),

  generatePDF: (pdfParams) =>
    instance({
      method: 'GET',
      url: `/pdf-erstellen/?user_id=${pdfParams.userId}&fabric_id=[${pdfParams.fabricIds}]&isWatchList=${pdfParams.isWatchList}&set_id=${pdfParams.setId}&hs1=${pdfParams.hs1}&hs2=${pdfParams.hs2}&hs3=${pdfParams.hs3}&product_subgroup_id_1=${pdfParams.product_subgroup_id_1}&product_subgroup_id_2=${pdfParams.product_subgroup_id_2}&product_subgroup_id_3=${pdfParams.product_subgroup_id_3}&scene_name=${pdfParams.scene_name}&folder_name=${pdfParams.folder_name}&shadow_color=${pdfParams.shadow_color}`,
      responseType: 'blob',
    }),

  postUserData: (formData) =>
    instance({
      method: 'POST',
      url: `/api/sattler/setUserCompanyData`,
      data: formData,
    }),

  getUserData: (userId) =>
    instance({
      method: 'GET',
      url: `/api/sattler/getUserData?userId=${userId}`,
    }),
  getUserByActivationCode: (activationCode) =>
    instance({
      method: 'GET',
      url: `/api/sattler/getUserByActivationCode?a_c=${activationCode}`,
    }),
};
