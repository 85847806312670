import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import CardActionArea from '@material-ui/core/CardActionArea';
import clsx from 'clsx';
import { useData } from '../DataProvider';
import { closeFabricViewDialog } from '../Subjects/subjects';
import { getSelectedItemById } from '../../util/util';

const styles = (muiBaseTheme) => ({
  card: {
    maxWidth: 160,
    margin: 'auto',
    width: 124,
    height: 160,
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
    },
  },
  media: {
    paddingTop: '53.25%',
  },
  content: {
    textAlign: 'left',
    padding: 10,
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 3}px 0`,
  },
  heading: {
    fontWeight: 'bold',
  },
  subheading: {
    lineHeight: 1.8,
  },
  avatar: {
    display: 'inline-block',
    border: '2px solid white',
    '&:not(:first-of-type)': {
      marginLeft: -muiBaseTheme.spacing.unit,
    },
  },
  actionArea: {
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  cardBorder: {
    border: '#b07f7f75',
    borderStyle: 'groove',
  },
});

function CustomCard({ classes, cardInfos, hotspotIndex }) {
  const { selectedItems, setSelectedItems, jsonData, selectedSceneIndex } = useData();
  const headerText = cardInfos.product.post_title;
  let collectionText;
  let designText;
  let isSelected = false;

  const fabricImageURL = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_FOLDER}${cardInfos.product.post_title}.jpg`;

  if (hotspotIndex !== undefined) {
    isSelected = cardInfos.product.post_title === selectedItems[hotspotIndex].fabric.post_title;
  }

  const foundCollection = jsonData.collections.find((el) => el.id === Number(cardInfos.product.collection));
  if (foundCollection) {
    collectionText = foundCollection.post_title;
  }

  const foundDesign = jsonData.design_lines.find((el) => el.id === Number(cardInfos.product.design_line));
  if (foundDesign) {
    designText = foundDesign.post_title;
  }

  const onClose = () => {
    const currentScene = jsonData.scenes[selectedSceneIndex];

    jsonData.scenes.map((scene) => {
      scene.scene.hotspots.map((hotspot) => {
        if (cardInfos.product.product_subgroup.includes(hotspot.product_group) && hotspot.product_group !== '7318') {
          getSelectedItemById(selectedItems, hotspot.product_group).fabric = cardInfos.product;
          setSelectedItems({ ...selectedItems });
        }
      });
    });

    if (Object.values(currentScene.fabrics).includes(cardInfos.product.id)) {
      currentScene.scene.hotspots.map((hotspot) => {
        if (cardInfos.product.product_subgroup.includes(hotspot.product_group)) {
          getSelectedItemById(selectedItems, hotspot.product_group).fabric = cardInfos.product;
          setSelectedItems({ ...selectedItems });
        } else if (hotspot.product_group !== '7318') {
          closeFabricViewDialog({ error: true });
        }
      });
      closeFabricViewDialog({ error: false });
    } else {
      closeFabricViewDialog({ error: true });
    }
  };

  return (
    <div className="App">
      <Card className={clsx(classes.card, isSelected ? classes.cardBorder : null)}>
        <CardActionArea
          classes={{
            root: classes.actionArea,
          }}
        >
          <CardMedia className={classes.media} image={fabricImageURL} onClick={onClose} />
          <CardContent className={classes.content}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <div>
                <Typography
                  className="MuiTypography--heading"
                  variant="h2"
                  gutterBottom
                  style={{ fontSize: '1rem', textTransform: 'capitalize' }}
                >
                  {`${headerText.substring(0, 3)} ${headerText.substring(3, 6)}`}
                </Typography>
              </div>
              <div />
            </div>
            <Typography className="MuiTypography--subheading" variant="caption">
              {collectionText}
              <br />
              {designText}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

CustomCard.propTypes = {
  cardInfos: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isDraggable: PropTypes.bool,
  moveCard: PropTypes.func,
  hotspotIndex: PropTypes.number,
};

CustomCard.defaultProps = {
  moveCard: null,
  isDraggable: null,
  hotspotIndex: 0,
};

const PaginationCard = withStyles(styles)(CustomCard);

export default PaginationCard;
