import { Button, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useMediaQuery } from 'react-responsive';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { openFabricViewDialog } from '../Subjects/subjects';
import WatchListDialog from '../WatchListDialog/WatchListDialog';
import { useData } from '../DataProvider';

export default function FooterCarousel({ showThumbs }) {
  const { watchMap, selectColor } = useData();
  const [openDialog, setOpenDialog] = useState(false);

  const Mobile = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 750 });
    return isTablet ? children : null;
  };

  const Phone = ({ children }) => {
    const isPhone = useMediaQuery({ maxWidth: 750 });
    return isPhone ? children : null;
  };

  const footerStyles = {
    root: {
      position: 'relative',
      width: '100%',
      backgroundColor: '#f9f9f9',
    },
    searchIconStyles: {
      borderStyle: 'none',
      textTransform: 'capitalize',
      minWidth: 0,
      margin: 0,
      padding: 0,
    },
    noBorder: {
      borderStyle: 'none',
      textTransform: 'capitalize',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 5,
    },
    buttonLeft: {
      borderStyle: 'none',
      float: 'left',
    },

  };

  function showNumberOfItems() {
    if (watchMap.size > 0) return `(${watchMap.size})`;
    return '';
  }

  return (
    <div style={footerStyles.root}>
      <Mobile>
        <Grid container alignItems="center">
          <Grid item xs={9} sm={6} style={{ display: 'flex' }}>
            <Button
              type="button"
              style={{ borderStyle: 'none' }}
              onClick={showThumbs}
            >
              TOPLINE Modern
              <ExpandLessIcon />
            </Button>
          </Grid>
          <Grid item xs={3} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div>
              <div style={footerStyles.flexCenter}>
                <SearchIcon />
                <Button
                  style={footerStyles.noBorder}
                  type="button"
                  onClick={() => openFabricViewDialog(1)}
                >
                  Stoffsuche
                </Button>
              </div>
            </div>
            <div style={footerStyles.flexCenter}>
              <FavoriteIcon style={{ color: selectColor }} />
              <Button
                style={footerStyles.noBorder}
                type="button"
                onClick={() => setOpenDialog(true)}
              >
                {`Merkliste ${showNumberOfItems()}`}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Mobile>
      <Phone>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <Button
              type="button"
              style={footerStyles.buttonLeft}
              onClick={showThumbs}
            >
              TOPLINE Modern
              <ExpandLessIcon />
            </Button>
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              style={footerStyles.searchIconStyles}
              type="button"
            >
              <SearchIcon onClick={() => openFabricViewDialog(1)} />
            </Button>
            <Button
              style={{
                borderStyle: 'none', textTransform: 'capitalize', minWidth: 0, margin: 0, padding: 0,
              }}
              type="button"
              onClick={() => setOpenDialog(true)}
            >
              <FavoriteIcon style={{ color: selectColor }} />
            </Button>
          </Grid>
        </Grid>
      </Phone>
      {openDialog && <WatchListDialog handleClose={() => setOpenDialog(false)} />}
    </div>
  );
}

FooterCarousel.propTypes = {
  showThumbs: PropTypes.func.isRequired,
};
