import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useData } from '../DataProvider';
import WatchListAccordion from './WatchListAccordion';
import {
  getAttributeById,
  getCollectionById,
  getDesignlineById,
  getMaterialById,
  isAllWeather,
  isCBA,
  isUV,
} from '../../util/util';

import cbaImg from '../../assets/collections/_CBA_silber4C.png';
import allWeatherImg from '../../assets/collections/All Weather.png';
// import IpcImg from '../../assets/collections/IPC finish.png';
import uvSafetyImg from '../../assets/collections/UV Schutz 2.5 Prozent V2.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    textAlign: 'left',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    height: 82,
  },
  row: {
    display: 'flex',
  },
  marginBottom: {
    marginBottom: '5',
  },
  col: {
    flex: 1,
  },
  card: {
    minWidth: 600,
    padding: '20px',
    marginBottom: 10,
    background: '#F8F8F8',
    fontSize: '14px',
  },
}));

export default function WatchListCardTypeA({ cardItem }) {
  const { jsonData } = useData();
  const classes = useStyles();

  const src = `${process.env.REACT_APP_BASE_URL}/wp-content/uploads/stoffdetailansicht/${cardItem.post_title}/hd.jpg`;
  const { t } = useTranslation();

  const isPhone = useMediaQuery({ maxWidth: 750 }) || window.innerHeight < 400;

  const getAttributes = () => {
    if (cardItem.attribute_set) {
      const attributes = cardItem.attribute_set
        .split(',')
        .filter((elem) => elem !== '')
        .map((elem) => {
          return `${getAttributeById(elem, jsonData).post_title}`;
        });
      return attributes;
    }
    return '';
  };

  const fabricNumber =
    cardItem.customer_set_alt_article_number && cardItem.customer_set_alt_article_number.length > 3
      ? cardItem.customer_set_alt_article_number
      : cardItem.post_title;

  const fabricName =
    cardItem.customer_set_alt_fabric_name && cardItem.customer_set_alt_fabric_name.length > 3
      ? cardItem.customer_set_alt_fabric_name
      : cardItem.fabric_name;

  return (
    <Card className={classes.card} style={{ minWidth: isPhone ? 0 : 600 }}>
      <div className={classes.root}>
        <CardMedia className={classes.cover} image={src} title="Stofffilter" />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h6">
              <b> {`${fabricNumber.substring(0, 3)} ${cardItem.post_title.substring(3, 6)}`}</b>
            </Typography>
            <Typography component="h6">{fabricName}</Typography>
          </CardContent>
        </div>
        <div style={{ marginLeft: '10%' }}>
          {isCBA(cardItem.collection) && <img src={cbaImg} alt="cbaImg" width="50" style={{ marginRight: 10 }} />}
          {isUV(cardItem.collection) && (
            <img src={uvSafetyImg} alt="uvSafetyImg" width="100" style={{ marginRight: 10 }} />
          )}
          {isAllWeather(cardItem.collection) && (
            <img src={allWeatherImg} alt="allWeatherImg" width="50" style={{ marginRight: 10 }} />
          )}
        </div>
      </div>
      <Grid container style={{ textAlign: 'left', marginBottom: 20 }}>
        {cardItem.collection !== '' && (
          <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={3} md={3}>
            <b>{t('quality')}:</b>
          </Grid>
        )}
        <Grid classname={classes.marginBottom} item xs={8} md={8}>
          {cardItem.collection !== '' ? getCollectionById(cardItem.collection, jsonData).post_title : ''}
        </Grid>

        {cardItem.design_line !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={3} md={3}>
              <b>{t('designline')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} item xs={8} md={8}>
              {cardItem.design_line !== '' ? getDesignlineById(cardItem.design_line, jsonData).post_title : ''}
            </Grid>
          </>
        )}
        {cardItem.material !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={3} md={3}>
              <b>{t('Material')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} item xs={8} md={8}>
              {cardItem.material !== '' ? getMaterialById(cardItem.material, jsonData).post_title : ''}
            </Grid>
          </>
        )}
        {cardItem.attribute_set !== '' && (
          <>
            <Grid style={{ textTransform: 'capitalize' }} classname={classes.marginBottom} item xs={3} md={3}>
              <b>{t('Spezielle Eigenschaften')}:</b>
            </Grid>
            <Grid classname={classes.marginBottom} item xs={8} md={8}>
              {cardItem.attribute_set !== ''
                ? getAttributes()
                    .join(', ')
                    .replace(/, ([^,]*)$/, ', $1')
                : ''}
            </Grid>
          </>
        )}
      </Grid>

      <WatchListAccordion card={cardItem} />
    </Card>
  );
}

WatchListCardTypeA.propTypes = {
  cardItem: PropTypes.instanceOf(Object).isRequired,
  hotspotId: PropTypes.string,
};

WatchListCardTypeA.defaultProps = {
  hotspotId: 0,
};
