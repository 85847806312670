import { createMuiTheme } from '@material-ui/core/styles';
import { responsiveFontSizes } from '@material-ui/core';

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ['Arial'].join(','),
  },
  palette: {
    type: 'light',
    primary: {
      main: '#1730a4',
      box: '#F8F8F8',
    },
    background: {
      default: '#fff',
    },
    secondary: {
      main: '#78BACC',
    },
    text: {
      primary: '#414141',
    },
    select: {
      color: '#EFC743',
    },
    wall: {
      color: '#eee3e3',
    },
  },
  MuiTabsIndicator: {
    backgroundColor: 'red',
  },
});

const theme = responsiveFontSizes(muiTheme);
export default theme;
