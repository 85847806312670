import React from 'react';
import QrReader from 'react-qr-reader';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useData } from '../DataProvider';
import { getSelectedItemById } from '../../util/util';

export default function QRCodeViewer({ done }) {
  const { jsonData, selectedItems, setSelectedItems, setSearchText, selectedSceneIndex } = useData();
  const isPhoneLandScape = useMediaQuery({ maxWidth: 950, orientation: 'landscape' });

  const handleScan = (qrCodeFabricId) => {
    if (qrCodeFabricId) {
      const qrCodeLink = qrCodeFabricId;
      const url = new URL(qrCodeLink);
      const fabric = url.searchParams.get('f');
      if (!fabric) {
        done('Falscher QR Code gescannt');
        return;
      }
      const foundItem = Object.values(jsonData.fabrics)
        .map((fabricArray) => fabricArray.filter((fabricList) => fabricList.post_title === fabric))
        .filter((elem) => elem.length > 0)[0][0];
      if (foundItem) {
        setSearchText(fabric);

        const currentScene = jsonData.scenes[selectedSceneIndex];

        jsonData.scenes.map((scene) => {
          scene.scene.hotspots.map((hotspot) => {
            if (foundItem.product_subgroup.includes(hotspot.product_group) && hotspot.product_group !== '7318') {
              getSelectedItemById(selectedItems, hotspot.product_group).fabric = foundItem;
              setSelectedItems({ ...selectedItems });
            }
          });
        });

        if (Object.values(currentScene.fabrics).includes(foundItem.id)) {
          currentScene.scene.hotspots.map((hotspot) => {
            if (foundItem.product_subgroup.includes(hotspot.product_group)) {
              getSelectedItemById(selectedItems, hotspot.product_group).fabric = foundItem;
              setSelectedItems({ ...selectedItems });
            } else if (hotspot.product_group !== '7318') {
              // closeFabricViewDialog({ error: true });
            }
          });
          done();
        } else {
          done('Stoff nicht in Szene enthalten');
        }
      } else {
        done('Stoff nicht in Länder/Kundenset enthalten');
      }
    }
  };

  const handleError = (err) => {
    alert(err);
  };

  return (
    <div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: isPhoneLandScape ? '50%' : '100%', margin: '0 auto', height: '90%' }}
      />
    </div>
  );
}

QRCodeViewer.propTypes = {
  done: PropTypes.func.isRequired,
};
