import { Subject } from 'rxjs';

const triggerOpenDialogSubject$ = new Subject(0);
const triggerCloseDialogSubject$ = new Subject(0);
const triggerOpenMiniViewDialogSubject$ = new Subject();
const triggerCloseMiniViewDialogSubject$ = new Subject();
const triggerCompareViewSubject$ = new Subject();
const triggerInquiryDialogSubject$ = new Subject();

export function openFabricViewDialog(value) {
  return triggerOpenDialogSubject$.next(value);
}

export function getTriggerOpenDialog() {
  return triggerOpenDialogSubject$;
}

export function closeFabricViewDialog(error) {
  return triggerCloseDialogSubject$.next(error);
}

export function getTriggerCloseDialog() {
  return triggerCloseDialogSubject$;
}

export function openMiniView() {
  return triggerOpenMiniViewDialogSubject$.next();
}

export function getTriggerOpenMiniView() {
  return triggerOpenMiniViewDialogSubject$;
}

export function closeMiniView() {
  return triggerCloseMiniViewDialogSubject$.next();
}

export function getTriggerCloseMiniView() {
  return triggerCloseMiniViewDialogSubject$;
}

export function updateCompareView() {
  return triggerCompareViewSubject$.next();
}

export function onValueChange() {
  return triggerCompareViewSubject$;
}

export function openInquiryDialog(id) {
  return triggerInquiryDialogSubject$.next(id);
}

export function getTriggerInquiryDialog() {
  return triggerInquiryDialogSubject$;
}
