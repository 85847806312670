import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Button } from '@material-ui/core';
import { useData } from '../DataProvider';
import WatchListCardTypeA from './WatchListCardTypeA';
import api from '../../api/api';
import { getSelectedItemById } from '../../util/util';

export default function WatchListDialog({ handleClose }) {
  const maxWidth = 'md';
  const { watchMap, selectedItems, userId, setId, jsonData, selectedSceneIndex } = useData();
  const text = 'STOFFDETAILS v0.5 - 31.01.2022';

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  const downloadPDF = async () => {
    const pdfParams = {
      isWatchList: true,
      userId,
      fabricIds: Array.from(watchMap).map(([key, value]) => value.product.id),
      setId,
    };

    const pdf = await api.generatePDF(pdfParams);
    const url = URL.createObjectURL(pdf.data);
    window.open(url);
  };

  return (
    <>
      <Dialog fullScreen maxWidth={maxWidth} open onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <DialogTitle id="max-width-dialog-title">
          {text}
          <IconButton aria-label="close" style={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {jsonData.scenes[selectedSceneIndex].scene.hotspots.map((hotspot) => {
            if (hotspot.product_group !== '7318')
              return <WatchListCardTypeA cardItem={getSelectedItemById(selectedItems, hotspot.product_group).fabric} />;
          })}
          <Button style={{ textTransform: 'capitalize' }} type="button" onClick={downloadPDF}>
            PDF-Download
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}

WatchListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};
