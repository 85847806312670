import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Link, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Container from '@material-ui/core/Container';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import embedPicture from '../assets/embed/design-selector-suntex@2x.png';
import QRIcon from '../assets/others/qr_code_icon.svg';
import FabricSearchDialog from './FabricSearchDialog';
import QRCodeDialog from './QRCode/QRCodeDialog';
import logo from '../assets/logo/Sattler-blau.png';
import { getTriggerCloseDialog } from './Subjects/subjects';
import { useData } from './DataProvider';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  buttonStyles: {
    border: '2px solid #F4F2F3',
    height: 35,
    background: 'rgb(255, 255, 255, 0.8)',
    marginRight: 10,
  },
  buttonStyles1: {
    border: '2px solid #F4F2F3',
    height: 70,
    background: 'rgb(255, 255, 255, 0.8)',
    marginRight: 20,
    marginLeft: 40,
  },
  imageStyles: {
    minHeight: 35,
    background: "url('img/design-selector-suntex.png')",
    height: '8.4vh',
    '@media (min-width:768px)': {
      height: 30,
      marginTop: 20,
    },
    '@media (max-width:950px)': {
      height: 30,
      marginTop: 0,
    },
  },
}));

export default function NavigationBar() {
  const classes = useStyles();
  const [openQRView, setOpenQRView] = useState(false);
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [responseState, setResponseState] = useState({ isError: false, msg: 'no error' });
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { userData, userId } = useData();

  getTriggerCloseDialog().subscribe(({ error }) => {
    setOpenSearchDialog(false);

    if (error) {
      setResponseState({ isError: false, msg: 'Stoff passt nicht auf Szene' });
      setOpen(true);
    }
  });

  const hideQRCodeDialog = (errorString) => {
    if (errorString && typeof errorString === 'string') {
      setResponseState({ isError: true, msg: errorString });
      setOpen(true);
    }

    setOpenQRView(false);
  };

  const Phone = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 550, orientation: 'portrait' });
    return isMobile ? children : null;
  };

  const PhoneLandScape = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 551, maxWidth: 950, orientation: 'landscape' });
    return isMobile ? children : null;
  };

  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 551, maxWidth: 950, orientation: 'portrait' });
    return isTablet ? children : null;
  };

  const TabletLandscape = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth: 950 });
    return isMobile ? children : null;
  };

  return (
    <div>
      <Phone>
        <Container
          style={{
            padding: 20,
            display: 'flex',
          }}
        >
          <Grid container spacing={2} justify="flex-start">
            <Grid style={{ textAlign: 'center' }} item xs={6}>
              <img
                alt="Logo"
                width="100"
                src={
                  userData.data && userData.data.data.userData.user_logo
                    ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
                        userData.data.data.userData.user_logo
                      }`
                    : logo
                }
              />
            </Grid>
            <Grid style={{ margin: -10, textAlign: 'center' }} item xs={6}>
              <Link
                style={{ margin: 0, minWidth: '50%', width: '100%' }}
                href={`https://sattler.datengarage.com?${userId}`}
              >
                <Button
                  style={{
                    border: '1px solid #F4F2F3',
                    height: 50,
                    marginTop: 10,
                    marginLeft: 10,
                    background: 'rgb(255, 255, 255, 0.8)',
                  }}
                >
                  <div style={{ marginRight: 5 }}>Design Selector</div>
                  <img alt="" src={embedPicture} className={classes.imageStyles} />
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.buttonStyles1}
                style={{ margin: 0, minWidth: '50%' }}
                startIcon={<img src={QRIcon} alt="QR Code Bild" style={{ height: 35 }} />}
                onClick={() => setOpenQRView(true)}
              >
                QR-Code scannen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.buttonStyles1}
                style={{ margin: 0, minWidth: '50%', width: '100%' }}
                startIcon={<SearchIcon style={{ marginRight: 10, width: 35, height: 35 }} />}
                onClick={() => setOpenSearchDialog(true)}
              >
                Stoff
                <br /> Suchen
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Phone>
      <PhoneLandScape>
        <Container
          style={{
            display: 'flex',
            top: 0,
            padding: 0,
            position: 'absolute',
          }}
        >
          <Grid
            style={{
              width: '100%',
              zIndex: 1,
              background: 'rgb(226, 230, 218, 0.7)',
              padding: '5px 5px 0 5px',
              margin: '-8px 0 -8px 0',
            }}
            container
            spacing={2}
          >
            <Grid item>
              <img
                width="100"
                src={
                  userData.data && userData.data.data.userData.user_logo
                    ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
                        userData.data.data.userData.user_logo
                      }`
                    : logo
                }
                className={classes.imageStyles}
                alt="Logo"
                style={{ height: 35 }}
              />
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Button
                    className={classes.buttonStyles}
                    startIcon={<img src={QRIcon} alt="QR Code Bild" style={{ height: 35 }} />}
                    onClick={() => setOpenQRView(true)}
                  >
                    QR-Code
                  </Button>
                  <Button
                    className={classes.buttonStyles}
                    startIcon={<SearchIcon style={{ marginRight: 10, width: 35, height: 35 }} />}
                    onClick={() => setOpenSearchDialog(true)}
                  >
                    Suchen
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <div>
                  <Link
                    href={`https://sattler.datengarage.com?${userId}`}
                    style={{ display: 'flex', alignItems: 'center', float: 'left' }}
                  >
                    <Button className={classes.buttonStyles}>Design Selector</Button>
                  </Link>
                  <img alt="" src={embedPicture} className={classes.imageStyles} style={{ height: 35 }} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </PhoneLandScape>
      <Tablet>
        <Container
          style={{
            padding: 20,
            display: 'flex',
          }}
        >
          <Grid container spacing={2} justify="flex-start">
            <Grid item xs={6} style={{ margin: 0, textAlign: 'center' }}>
              <img
                width="100"
                alt="Logo"
                src={
                  userData.data && userData.data.data.userData.user_logo
                    ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
                        userData.data.data.userData.user_logo
                      }`
                    : logo
                }
              />
            </Grid>
            <Grid style={{ margin: -10, textAlign: 'center' }} item xs={6}>
              <Link
                style={{ margin: 0, minWidth: '50%', width: '100%' }}
                href={`https://sattler.datengarage.com?${userId}`}
              >
                <Button
                  style={{
                    border: '2px solid #F4F2F3',
                    height: 50,
                    marginTop: 10,
                    marginLeft: 10,
                    background: 'rgb(255, 255, 255, 0.8)',
                  }}
                >
                  <div style={{ marginRight: 5 }}>Design Selector</div>
                  <img alt="" src={embedPicture} className={classes.imageStyles} />
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.buttonStyles1}
                style={{ margin: 0, minWidth: '50%', width: '100%' }}
                startIcon={<img src={QRIcon} alt="QR Code Bild" style={{ height: 35 }} />}
                onClick={() => setOpenQRView(true)}
              >
                QR-Code scannen
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.buttonStyles1}
                style={{ margin: 0, minWidth: '50%', width: '100%' }}
                startIcon={<SearchIcon style={{ marginRight: 10, width: 35, height: 35 }} />}
                onClick={() => setOpenSearchDialog(true)}
              >
                Suchen
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Tablet>
      <TabletLandscape>
        <Container>
          <div style={{ marginBottom: 10, marginTop: 5 }}>
            <img
              width="100"
              src={
                userData.data && userData.data.data.userData.user_logo
                  ? `${process.env.REACT_APP_BASE_URL + process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/company_logo/${
                      userData.data.data.userData.user_logo
                    }`
                  : logo
              }
              style={{ float: 'left' }}
              className={classes.imageStyles}
              alt="Logo"
            />
            <Button
              className={classes.buttonStyles1}
              startIcon={<img src={QRIcon} alt="QR Code Bild" style={{ height: 35 }} />}
              onClick={() => setOpenQRView(true)}
            >
              QR-Code scannen
            </Button>

            <Button
              className={classes.buttonStyles1}
              startIcon={<SearchIcon style={{ marginRight: 10, width: 35, height: 35 }} />}
              onClick={() => setOpenSearchDialog(true)}
            >
              {t('search_fabric')}
            </Button>
            <Link style={{ float: 'right' }} href={`https://sattler.datengarage.com?${userId}`}>
              <Button
                style={{
                  border: '2px solid #F4F2F3',
                  height: 70,
                  background: 'rgb(255, 255, 255, 0.8)',
                }}
              >
                <div style={{ marginRight: 5 }}>Zum Design Selector</div>
                <img alt="" src={embedPicture} className={classes.imageStyles} />
              </Button>
            </Link>
          </div>
        </Container>
      </TabletLandscape>

      {openSearchDialog && (
        <FabricSearchDialog onClose={() => setOpenSearchDialog(false)} openQRView={() => setOpenQRView(true)} />
      )}
      {openQRView && <QRCodeDialog onClose={(errorString) => hideQRCodeDialog(errorString)} />}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={responseState.msg}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      />
    </div>
  );
}
