import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColorById, getSelectedItemById } from '../util/util';
import { useData } from './DataProvider';

const LayeringComponent = ({
  imageRef,
  index,
  sceneItem,
  isFullScreen,
  fullScreenStyles,
  layeringStyle,
  wallColor,
  selectedItems,
  jsonData,
  opacity,
}) => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isPhone = useMediaQuery({ maxWidth: 950 }) || window.innerHeight < 400;
  const isTablet = useMediaQuery({ minWidth: 766, minHeight: 1000, orientation: 'portrait' });

  let height = 680;
  if (isPhone && isLandscape) height = window.innerHeight;
  else if (isTablet) height = 680;
  else if (isPhone) height = 300;
  if (isFullScreen) height = 880;
  const srcSubPath = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${sceneItem.scene.sub_path}/wall_matt.png`;
  const srcMain = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${sceneItem.scene.sub_path}/main.png`;
  const srcWall = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${sceneItem.scene.sub_path}wall_structure.png`;
  const errorSrc = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  const [loadedStateObj, setLoadedStateObj] = useState({
    img1: false,
    img2: false,
    img3: false,
    img4: false,
    img5: false,
  });

  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const { setLoading } = useData();

  const carouselStyles = {
    position: 'relative',
    height,
    zIndex: opacity,
  };

  const visualStyles = {
    display: 'none',
  };

  const handleChange = (prop, value) => {
    setLoadedStateObj({ ...loadedStateObj, [prop]: value });
  };

  useEffect(() => {
    if (Object.entries(loadedStateObj).every((entry) => entry[1] === true)) {
      setAllImagesLoaded(true);
      setLoading(false);
    }
  }, [loadedStateObj, setLoading]);

  return (
    <div style={allImagesLoaded ? {} : visualStyles}>
      <div style={carouselStyles} id="imageContainer" className="imageCointainer">
        <img
          ref={imageRef}
          id={`image_ ${index}`}
          src={srcSubPath}
          alt="Zeigt ein Bild an."
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          style={{
            ...(isFullScreen && isLandscape ? fullScreenStyles : layeringStyle),
            background: wallColor.hex,
          }}
          onLoad={() => handleChange('img1', true)}
        />

        <img
          id={`image_ ${index}`}
          src={srcMain}
          alt="Zeigt ein Bild an."
          style={{
            ...(isFullScreen && isLandscape ? fullScreenStyles : layeringStyle),
          }}
          onLoad={() => handleChange('img2', true)}
        />

        {sceneItem.scene.hotspots &&
          sceneItem.scene.hotspots.map((hotspot) => {
            const srcFabric =
              hotspot.product_group !== '7318'
                ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}${
                    sceneItem.scene.sub_path
                  }${hotspot.subfolder}/${sceneItem.scene.sub_path.split('/')[2]}_${hotspot.subfolder}_${
                    getSelectedItemById(selectedItems, hotspot.product_group)?.fabric?.post_title
                  }.png`
                : '';
            return hotspot.product_group !== '7318' ? (
              <div key={hotspot.product_group}>
                <img
                  id={`image_ ${index}`}
                  src={srcFabric}
                  alt="Zeigt ein Bild an."
                  onError={(e) => {
                    e.target.src = errorSrc;
                  }}
                  style={{
                    ...(isFullScreen && isLandscape ? fullScreenStyles : layeringStyle),
                  }}
                  onLoad={() => handleChange('img3', true)}
                />
              </div>
            ) : (
              <div key={hotspot.product_group}> </div>
            );
          })}

        <img
          id={`image_ ${index}`}
          src={srcWall}
          alt="Zeigt ein Bild an."
          style={{
            ...(isFullScreen && isLandscape ? fullScreenStyles : layeringStyle),
          }}
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          onLoad={() => handleChange('img4', true)}
        />
        <img
          id={`image_ ${index}`}
          src={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOADS_SCENES_FOLDER}/${
            sceneItem.scene.sub_path
          }${sceneItem.scene.post_title}_05_${
            getColorById(selectedItems[0].fabric.color_primary, jsonData).post_title
          }.png`}
          alt="Zeigt ein Bild an."
          onError={(e) => {
            e.target.src = errorSrc;
          }}
          style={{
            ...(isFullScreen && isLandscape ? fullScreenStyles : layeringStyle),
            opacity: 0.3,
          }}
          onLoad={() => handleChange('img5', true)}
        />
      </div>
    </div>
  );
};

export default LayeringComponent;
