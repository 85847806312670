import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import QRCodeViewer from './QRCodeViewer';

export default function QRCodeDialog({ onClose }) {
  const maxWidth = 'lg'; // sm, md, lg
  const history = useHistory();

  const closeButton = {
    position: 'absolute',
    right: '1%',
    top: '1%',
  };

  function handleClose(errorString) {
    if (onClose) onClose(errorString);
    else history.push('/');
  }

  return (
    <>
      <Dialog fullScreen maxWidth={maxWidth} open onClose={handleClose} aria-labelledby="qrCode-title">
        <DialogTitle id="qrCode-title">Bitte scannen Sie den QR-Code</DialogTitle>
        <DialogActions>
          <IconButton style={closeButton} aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <QRCodeViewer done={(errorString) => handleClose(errorString)} />
        </DialogContent>
      </Dialog>
    </>
  );
}

QRCodeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
